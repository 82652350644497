<template>
    <div class="Error">
        <div class="ErrorImage"><img src="@/assets/images/notfound.png" /></div>
        <div class="ErrorHead">
            This page is unknown or does not exist.
        </div>
        <div class="ErrorSub">
            Sorry about that, but the page you looking for is not available.
        </div>
        <router-link to="/"
            ><div class="ErrorButton">
                <Button primary name="Go to homepage" /></div
        ></router-link>
    </div>
</template>

<script>
import Button from 'componentsv2/Button'
export default {
    components: { Button },
}
</script>

<style lang="scss">
@import '404.scss';
</style>
